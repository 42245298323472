import { Pipe, PipeTransform } from '@angular/core';
import { DimensionsEnum } from '@dmc-ng/data-access/countings';

@Pipe({
  name: 'columnDimensionName',
  standalone: true,
})
export class ColumnDimensionNamePipe implements PipeTransform {
  transform(column: string): string {
    switch (column) {
      case DimensionsEnum.AgeGroup5:
        return 'Age Group';
      case DimensionsEnum.FamilySquare:
        return 'Family Square';
      case DimensionsEnum.IrisCode:
        return 'Iris Code';
      case DimensionsEnum.ZipCode:
        return 'Zip Code';
      default:
        return column;
    }
  }
}
