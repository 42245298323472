@if (countingVersion$ | async; as version) {
  <div class="container">
    <div class="top">
      <h3>
        Summary of the counting version:
        <span>{{ version.countingVersion.version }}</span>
      </h3>
      <div class="d-flex flex-row align-items-center gap-2">
        <mat-icon
          class="grey-stroke"
          svgIcon="calendar-outline"
        ></mat-icon>
        <span class="infos">{{
          version.countingVersion.createAt | date: 'EEEE, MMMM d'
        }}</span>
        <div class="separator"></div>
        <div class="infos">
          {{ version.countingVersion.createAt | date: 'h:mm' }}
        </div>
        <div class="separator"></div>
        <div class="pic-text">
          <dmc-ng-avatar
            [classType]="'circle'"
            [value]="version.user.displayName"
            [width]="24"
            [pictureUrl]="version.user.pictureUrl"
          ></dmc-ng-avatar>
          <span class="infos">{{ version.user.displayName }}</span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center gap-2 flex-wrap">
        <div class="row">
          <span class="label">Counting: </span>
          <span class="infos">{{ version.counting.name }}</span>
        </div>

        <div class="separator"></div>
        <span class="label">Organization:</span>
        <div class="pic-text">
          <dmc-ng-avatar
            [classType]="'circle'"
            [value]="version.organization.name"
            [width]="24"
            [pictureUrl]="version.organization.logoUrl"
          ></dmc-ng-avatar>
          <span class="infos">{{ version.organization.name }}</span>
        </div>
        <div class="separator"></div>
        <span class="label">Account:</span>
        <div class="pic-text">
          <dmc-ng-avatar
            [classType]="'circle'"
            [value]="version.account.name"
            [width]="24"
            [pictureUrl]="version.account.logoUrl"
          ></dmc-ng-avatar>
          <span class="infos">{{ version.account.name }}</span>
        </div>
      </div>
    </div>
    <div class="summary">
      <div
        class="d-flex flex-row align-items-center justify-content-between flex-wrap"
      >
        <dmc-ng-colored-card
          [colorCard]="coloredCardEnum.Purple"
          [paddingCard]="coloredCardPaddingEnum.Normal"
          icon="sms-fill"
        >
          <div nextIcon>
            <span class="title-colored-card">Counting Result</span>
          </div>
          <div underIcon>
            <span class="counter">{{
              version.countingVersion.result.total | numberFormat
            }}</span>
          </div>
        </dmc-ng-colored-card>
        <dmc-ng-colored-card
          [colorCard]="coloredCardEnum.Yellow"
          [paddingCard]="coloredCardPaddingEnum.Normal"
          icon="user3-fill"
        >
          <div nextIcon>
            <span class="title-colored-card">Max Reach</span>
          </div>
          <div underIcon>
            <span class="counter">{{
              version.countingVersion.maxReach | numberFormat
            }}</span>
          </div>
        </dmc-ng-colored-card>
        <dmc-ng-colored-card
          [colorCard]="coloredCardEnum.Blue"
          [paddingCard]="coloredCardPaddingEnum.Normal"
          icon="euro"
        >
          <div
            nextIcon
            class="align"
          >
            <span class="title-colored-card">Max Price</span>
          </div>
          <div underIcon>
            <span class="counter">{{
              version.countingVersion.maxPrice | numberFormat: true
            }}</span>
          </div>
        </dmc-ng-colored-card>
      </div>

      <div class="card-content">
        <div class="details">
          @if (version.countingVersion.result.counts) {
            <dmc-ng-block-card
              title="Count Details"
              description="Count by dimension"
            >
              <ng-container actions>
                <button
                  mat-stroked-button
                  color="primary"
                  class="medium"
                  [autofocus]="false"
                  (click)="exportCSVByDimension()"
                >
                  <mat-icon
                    class="icons"
                    svgIcon="download-csv"
                  ></mat-icon>
                  Export CSV
                </button>
              </ng-container>
              <ng-container content>
                <dmc-ng-table
                  [items]="filteredCounts"
                  [paginatorTotal]="
                    version.countingVersion.result.counts.length
                  "
                  [paginatorIndex]="index"
                  (paginatorChange)="paginatorIndexChanged($event)"
                >
                  <ng-container *ngFor="let column of columns">
                    <ng-template
                      let-item
                      dmcNgCell
                      [name]="column | columnDimensionName | titlecase"
                    >
                      <span>
                        @switch (column) {
                          @case (dimensionsEnum.Gender) {
                            {{
                              item.dimensions[column].value === genderEnum.Male
                                ? 'Male'
                                : 'Female'
                            }}
                          }
                          @default {
                            {{
                              item.dimensions[column].label ||
                                item.dimensions[column].value
                            }}
                          }
                        }
                      </span>
                    </ng-template>
                  </ng-container>

                  <ng-template
                    let-item
                    dmcNgCell
                    name="Total"
                  >
                    <span>{{ item.count | numberFormat }}</span>
                  </ng-template>
                </dmc-ng-table>
              </ng-container>
            </dmc-ng-block-card>
          }
          <dmc-ng-block-card
            title="Dataset"
            description="Your no-call database"
          >
            <ng-container content>
              @if (version.countingVersion.dataset) {
                <div class="dataset-details">
                  <div class="row">
                    <span class="label">Dataset Name :</span>
                    <span class="value">{{
                      version.countingVersion.dataset.datasetName
                    }}</span>
                  </div>

                  @if (version.countingVersion.dataset.campaigns) {
                    <span class="label">With campaign(s) :</span>
                    <div class="grid">
                      @for (
                        campaign of version.countingVersion.dataset.campaigns;
                        track $index
                      ) {
                        <div class="item">
                          <span class="value">
                            {{ campaign.name }}
                          </span>
                        </div>
                      }
                    </div>
                  }
                </div>
              } @else {
                <dmc-ng-informations
                  message="There are no dataset criteria"
                  type="info"
                  icon="info"
                ></dmc-ng-informations>
              }
            </ng-container>
          </dmc-ng-block-card>

          <dmc-ng-block-card
            title="Geographical"
            description="Customer-side sales contact details."
          >
            @if (geographicalType) {
              <ng-container actions>
                <button
                  mat-stroked-button
                  color="primary"
                  class="medium"
                  [autofocus]="false"
                  (click)="exportCSV(geographicalType)"
                >
                  <mat-icon
                    class="icons"
                    svgIcon="download-csv"
                  ></mat-icon>
                  Export CSV
                </button>
              </ng-container>
            }
            <ng-container content>
              @if (geographicalType) {
                @if (version.countingVersion.filters.geo; as geographical) {
                  @if (geographical.cities; as cities) {
                    <dmc-console-share-geographical-bloc
                      title="Cities"
                      [counter]="cities.entries.length"
                      (showLessItems)="showLessItems(geographicalType)"
                      (showMoreItems)="showMoreItems(geographicalType)"
                    >
                      <ng-container content>
                        <div class="row">
                          <span class="label">Radius</span>
                          <span class="value">{{ cities.radius }}</span>
                        </div>
                        <div class="grid medium">
                          @for (city of items; track $index) {
                            <div class="item">
                              <span class="value">
                                {{ city.code }}
                              </span>
                              <span>-</span>
                              <span class="value">{{ city.name }}</span>
                            </div>
                          }
                        </div>
                      </ng-container>
                    </dmc-console-share-geographical-bloc>
                  }

                  @if (geographical.departments; as departments) {
                    <dmc-console-share-geographical-bloc
                      title="Departments"
                      [counter]="departments.length"
                      (showLessItems)="showLessItems(geographicalType)"
                      (showMoreItems)="showMoreItems(geographicalType)"
                    >
                      <div
                        class="grid"
                        content
                      >
                        @for (department of items; track $index) {
                          <div class="item">
                            <span class="value">{{
                              version.resources.departments!!
                                | labelFromId: department
                            }}</span>
                          </div>
                        }
                      </div>
                    </dmc-console-share-geographical-bloc>
                  }

                  @if (geographical.districts; as districts) {
                    <dmc-console-share-geographical-bloc
                      title="Districts"
                      [counter]="districts.length"
                      (showLessItems)="showLessItems(geographicalType)"
                      (showMoreItems)="showMoreItems(geographicalType)"
                    >
                      <div
                        class="grid"
                        content
                      >
                        @for (district of items; track $index) {
                          <div class="item">
                            <span class="value">{{
                              version.resources.districts!!
                                | labelFromId: district
                            }}</span>
                          </div>
                        }
                      </div>
                    </dmc-console-share-geographical-bloc>
                  }

                  @if (geographical.zipCodes; as zipCodes) {
                    <dmc-console-share-geographical-bloc
                      title="Zip Codes"
                      [counter]="zipCodes.length"
                      (showLessItems)="showLessItems(geographicalType)"
                      (showMoreItems)="showMoreItems(geographicalType)"
                    >
                      <div
                        class="grid"
                        content
                      >
                        @for (zip of items; track $index) {
                          <div class="item">
                            <span class="value">{{ zip }}</span>
                          </div>
                        }
                      </div>
                    </dmc-console-share-geographical-bloc>
                  }

                  @if (geographical.irisCodes; as irisCodes) {
                    <dmc-console-share-geographical-bloc
                      title="Iris Codes"
                      [counter]="irisCodes.length"
                      (showLessItems)="showLessItems(geographicalType)"
                      (showMoreItems)="showMoreItems(geographicalType)"
                    >
                      <div
                        class="grid"
                        content
                      >
                        @for (iris of items; track $index) {
                          <div class="item">
                            <span class="value">{{ iris }}</span>
                          </div>
                        }
                      </div>
                    </dmc-console-share-geographical-bloc>
                  }

                  @if (geographical.coordinates; as coordinates) {
                    <dmc-console-share-geographical-bloc
                      title="POI"
                      [counter]="coordinates.length"
                      (showLessItems)="showLessItems(geographicalType)"
                      (showMoreItems)="showMoreItems(geographicalType)"
                    >
                      <ng-container content>
                        <dmc-ng-table
                          [items]="items"
                          [tableWidth]="'488px'"
                        >
                          <ng-template
                            let-item
                            dmcNgCell
                            name="Latitude"
                            width="204px"
                          >
                            <span>{{ item.lat }}</span>
                          </ng-template>

                          <ng-template
                            let-item
                            let-index="index"
                            dmcNgCell
                            name="Longitude"
                            width="204px"
                          >
                            <span>{{ item.lng }}</span>
                          </ng-template>

                          <ng-template
                            let-item
                            let-index="index"
                            dmcNgCell
                            name="Radius"
                            width="80px"
                          >
                            <span>{{ item.radius / 1000 }}</span>
                          </ng-template>
                        </dmc-ng-table>
                      </ng-container>
                    </dmc-console-share-geographical-bloc>
                  }
                }
              } @else {
                <dmc-ng-informations
                  message="There are no geographical criteria"
                  type="info"
                  icon="info"
                ></dmc-ng-informations>
              }
            </ng-container>
          </dmc-ng-block-card>

          <dmc-ng-block-card
            title="Define audience"
            description="The population you seek to reach."
          >
            <ng-container content>
              @if (version.countingVersion.filters.audience; as audience) {
                @if (audience.length > 0) {
                  <div class="segment-chips">
                    <div class="segments-container">
                      @for (
                        segment of audience;
                        track segment;
                        let index = $index
                      ) {
                        <div
                          class="segment"
                          [ngClass]="{ border: selectedSegmentIndex === index }"
                          (click)="selectSegment(index)"
                        >
                          <div class="icon-name">
                            <dmc-ng-tooltip
                              [text]="segment.name ?? ''"
                              class="name"
                              width="200px"
                            ></dmc-ng-tooltip>
                            <div class="icon-container">
                              <mat-icon
                                class="icons grey-stroke"
                                [svgIcon]="
                                  segment.exclude ? 'user-minus' : 'user-plus'
                                "
                              ></mat-icon>
                              {{ segment.exclude ? 'Exclude' : 'Include' }}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    <div class="chips-container">
                      @for (chip of chips; track $index) {
                        <mat-chip-row class="custom primary rounded badge">
                          {{ chip.value }}
                          <dmc-ng-badge
                            [width]="16"
                            [value]="chip.counter.toString()"
                            border="white"
                            color="secondary"
                          ></dmc-ng-badge>
                        </mat-chip-row>
                      }
                    </div>
                  </div>

                  <div class="card-content">
                    <div class="gender-checkbox">
                      <mat-checkbox
                        class="small fake-disabled"
                        [disabled]="true"
                        [checked]="
                          audience[selectedSegmentIndex].gender ===
                          genderEnum.Male
                        "
                        >Male</mat-checkbox
                      >
                      <mat-checkbox
                        class="small fake-disabled"
                        [disabled]="true"
                        [checked]="
                          audience[selectedSegmentIndex].gender ===
                          genderEnum.Female
                        "
                        >Female</mat-checkbox
                      >
                    </div>
                    @if (
                      audience[selectedSegmentIndex].ageGroups5 ||
                      audience[selectedSegmentIndex].familySquares
                    ) {
                      <div class="criteria">
                        <span class="title">Demographical criterias</span>
                        @if (audience[selectedSegmentIndex].ageGroups5) {
                          <span class="label">Age Groups</span>
                          <div class="grid">
                            @for (
                              age of audience[selectedSegmentIndex].ageGroups5;
                              track $index
                            ) {
                              <div class="item">
                                <span class="value">
                                  {{
                                    version.resources.ageGroups5!!
                                      | labelFromId: age
                                  }}
                                </span>
                              </div>
                            }
                          </div>
                        }

                        @if (audience[selectedSegmentIndex].familySquares) {
                          <span class="label">Family Square</span>
                          <div class="grid large">
                            @for (
                              family of audience[selectedSegmentIndex]
                                .familySquares;
                              track $index
                            ) {
                              <div class="item">
                                <span class="value">
                                  {{
                                    version.resources.familySquares!!
                                      | labelFromId: family : true
                                  }}</span
                                >
                              </div>
                            }
                          </div>
                        }
                      </div>
                    }
                    @if (
                      audience[selectedSegmentIndex].revenues &&
                      audience[selectedSegmentIndex].housings
                    ) {
                      <div class="criteria">
                        <span class="title">Financial criterias</span>
                        @if (audience[selectedSegmentIndex].revenues) {
                          <span class="label">Earnings</span>
                          <div class="grid">
                            @for (
                              earnings of audience[selectedSegmentIndex]
                                .revenues;
                              track $index
                            ) {
                              <div class="item">
                                <span class="value">{{
                                  version.resources.earnings!!
                                    | labelFromId: earnings
                                }}</span>
                              </div>
                            }
                          </div>
                        }

                        @if (audience[selectedSegmentIndex].housings) {
                          <span class="label">Housings</span>
                          <div class="grid">
                            @for (
                              housing of audience[selectedSegmentIndex]
                                .housings;
                              track $index
                            ) {
                              <div class="item">
                                <span class="value">{{
                                  version.resources.housings!!
                                    | labelFromId: housing
                                }}</span>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    }
                    <div class="criteria">
                      <mat-radio-group
                        [(ngModel)]="audience[selectedSegmentIndex].exclude"
                        [disabled]="true"
                        aria-labelledby="query-radio-group"
                        color="primary"
                        class="small-row"
                      >
                        <mat-radio-button
                          [value]="false"
                          class="small fake-disabled"
                          >Include</mat-radio-button
                        >
                        <mat-radio-button
                          [value]="true"
                          class="small fake-disabled"
                          >Exclude</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>
                  </div>
                }
              } @else {
                <dmc-ng-informations
                  message="There are no audience criteria"
                  type="info"
                  icon="info"
                ></dmc-ng-informations>
              }
            </ng-container>
          </dmc-ng-block-card>
        </div>
      </div>
    </div>
  </div>
}
