import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { CountModel, DimensionsEnum } from '@dmc-ng/data-access/countings';
import {
  AudienceShareEnum,
  DefineAudienceShareEnum,
  GenderEnum,
  GeographicalShareModel,
  selectPublicShareCountingVersion,
} from '@dmc-ng/data-access/public';
import { AvatarComponent } from '@dmc-ng/ui/avatar';
import { BadgeComponent } from '@dmc-ng/ui/badge';
import {
  BlockCardComponent,
  ColoredCardColorEnum,
  ColoredCardComponent,
  ColoredCardPaddingEnum,
  HeaderImageCardComponent,
} from '@dmc-ng/ui/card';
import { InformationsComponent } from '@dmc-ng/ui/informations';
import { NumberFormatPipe } from '@dmc-ng/ui/pipes';
import { TableCellDirective, TableComponent } from '@dmc-ng/ui/table';
import {
  TooltipColorEnum,
  TooltipComponent,
  TooltipPositionEnum,
} from '@dmc-ng/ui/tooltip';
import { Store } from '@ngrx/store';
import { isArray } from 'lodash';
import { Subject, distinctUntilChanged, filter, map, takeUntil } from 'rxjs';

import { GeographicalBlocComponent } from '../components/geographical-bloc/geographical-bloc.component';
import { GeographicalShareEnum } from '../models/enum/geographical-share.enum';
import { ColumnDimensionNamePipe } from '../pipes/column-dimension-name.pipe';
import { LabelFromIdPipe } from '../pipes/label-from-id.pipe';
import { ExportShareUtils } from '../utils/export-share.utils';

@Component({
  selector: 'dmc-console-share',
  standalone: true,
  imports: [
    CommonModule,
    HeaderImageCardComponent,
    MatRadioModule,
    MatCheckboxModule,
    BlockCardComponent,
    FormsModule,
    LabelFromIdPipe,
    InformationsComponent,
    NumberFormatPipe,
    AvatarComponent,
    ColoredCardComponent,
    MatButtonModule,
    MatIconModule,
    TableComponent,
    TableCellDirective,
    GeographicalBlocComponent,
    ColumnDimensionNamePipe,
    TooltipComponent,
    BadgeComponent,
    MatChipsModule,
  ],
  templateUrl: './share.component.html',
  styleUrl: './share.component.scss',
})
export class ShareComponent implements OnInit {
  countingVersion$ = this.store.select(selectPublicShareCountingVersion);

  readonly audienceEnum: typeof AudienceShareEnum = AudienceShareEnum;
  readonly genderEnum: typeof GenderEnum = GenderEnum;
  readonly coloredCardEnum: typeof ColoredCardColorEnum = ColoredCardColorEnum;
  readonly coloredCardPaddingEnum: typeof ColoredCardPaddingEnum =
    ColoredCardPaddingEnum;
  readonly geographicalShareEnum: typeof GeographicalShareEnum =
    GeographicalShareEnum;
  readonly definedAudienceEnum: typeof DefineAudienceShareEnum =
    DefineAudienceShareEnum;

  readonly tooltipPositionEnum: typeof TooltipPositionEnum =
    TooltipPositionEnum;
  readonly tooltipColorEnum: typeof TooltipColorEnum = TooltipColorEnum;

  readonly dimensionsEnum: typeof DimensionsEnum = DimensionsEnum;

  selectedSegmentIndex = 0;
  index = 0;
  chips: { value: AudienceShareEnum; counter: number }[] = [];

  geographicalData!: GeographicalShareModel;
  versionName!: string;
  canShowMore = false;
  isAllVisible = false;
  items: any[] = [];
  columns: string[] = [];
  counts: CountModel[] = [];
  filteredCounts: CountModel[] = [];
  paginatorIndex = 0;

  geographicalType: GeographicalShareEnum | undefined = undefined;

  private isDestroyed$: Subject<void> = new Subject();

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.countingVersion$
      .pipe(takeUntil(this.isDestroyed$), distinctUntilChanged())
      .subscribe((version) => {
        if (version) {
          this.geographicalData = version.countingVersion.filters.geo;
          this.versionName = version.countingVersion.version;

          if (
            version.countingVersion.filters.audience &&
            version.countingVersion.filters.audience.length > 0 &&
            version.countingVersion.filters.audience[0]
          ) {
            this.updateChipsCounters(
              version.countingVersion.filters.audience[0],
            );
          }
          if (version.countingVersion.filters.geo) {
            this.setGeographicalType(version.countingVersion.filters.geo);
          }

          if (version.countingVersion.result.counts) {
            this.extractColumns(version.countingVersion.result.counts);
            this.counts = version.countingVersion.result.counts;
            this.filteredCounts = this.counts.slice(0, 10);
          }
        }
      });
  }

  selectSegment(index: number): void {
    this.selectedSegmentIndex = index;
    this.chips = [];
    this.countingVersion$
      .pipe(
        takeUntil(this.isDestroyed$),
        distinctUntilChanged(),
        filter((version) => !!version),
        map((version) => version?.countingVersion.filters.audience[index]),
      )
      .subscribe((audienceFilters) =>
        this.updateChipsCounters(audienceFilters),
      );
  }

  showLessItems(type: GeographicalShareEnum): void {
    this.isAllVisible = false;
    this.countingVersion$
      .pipe(takeUntil(this.isDestroyed$), distinctUntilChanged())
      .subscribe((version) => {
        if (version) {
          switch (type) {
            case GeographicalShareEnum.City:
              this.items =
                version.countingVersion.filters.geo.cities?.entries.slice(
                  0,
                  5,
                ) || [];
              break;
            case GeographicalShareEnum.District:
              this.items =
                version.countingVersion.filters.geo.districts?.slice(0, 5) ||
                [];
              break;
            case GeographicalShareEnum.Department:
              this.items =
                version.countingVersion.filters.geo.departments?.slice(0, 5) ||
                [];
              break;
            case GeographicalShareEnum.Iris:
              this.items =
                version.countingVersion.filters.geo.irisCodes?.slice(0, 5) ||
                [];
              break;
            case GeographicalShareEnum.Zip:
              this.items =
                version.countingVersion.filters.geo.zipCodes?.slice(0, 5) || [];
              break;
            case GeographicalShareEnum.Coordinates:
              this.items =
                version.countingVersion.filters.geo.coordinates?.slice(0, 5) ||
                [];
              break;

            default:
              break;
          }
        }
      });
  }

  showMoreItems(type: GeographicalShareEnum): void {
    this.isAllVisible = true;
    this.countingVersion$
      .pipe(takeUntil(this.isDestroyed$), distinctUntilChanged())
      .subscribe((version) => {
        if (version) {
          switch (type) {
            case GeographicalShareEnum.City:
              this.items =
                version.countingVersion.filters.geo.cities?.entries || [];
              break;
            case GeographicalShareEnum.District:
              this.items = version.countingVersion.filters.geo.districts || [];
              break;
            case GeographicalShareEnum.Department:
              this.items =
                version.countingVersion.filters.geo.departments || [];
              break;
            case GeographicalShareEnum.Iris:
              this.items = version.countingVersion.filters.geo.irisCodes || [];
              break;
            case GeographicalShareEnum.Zip:
              this.items = version.countingVersion.filters.geo.zipCodes || [];
              break;
            case GeographicalShareEnum.Coordinates:
              this.items =
                version.countingVersion.filters.geo.coordinates || [];
              break;

            default:
              break;
          }
        }
      });
  }

  exportCSV(type: GeographicalShareEnum): void {
    const geoData = this.geographicalData[type];

    if (!geoData) {
      console.error(`No data found for type: ${type}`);
      return;
    }

    ExportShareUtils.exportGeographicalData(geoData, type, this.versionName);
  }

  exportCSVByDimension(): void {
    ExportShareUtils.exportCountsDetailsByDimensionsData(
      this.versionName,
      this.columns as DimensionsEnum[],
      this.counts,
    );
  }

  paginatorIndexChanged(pageIndex: number): void {
    const start = pageIndex * 10;
    this.filteredCounts = this.counts.slice(start, start + 10);
  }

  private setGeographicalType(geoObject: any): void {
    const values = Object.values(GeographicalShareEnum);
    for (const value of values) {
      if (
        geoObject[value] ||
        (isArray(geoObject[value]) && geoObject[value].length)
      ) {
        this.geographicalType = value as GeographicalShareEnum;

        if (isArray(geoObject[value])) {
          if (geoObject[value].length > 5) {
            this.canShowMore = true;
            this.isAllVisible = false;
            this.items = geoObject[value].slice(0, 5);
          } else {
            this.isAllVisible = true;
            this.items = geoObject[value];
          }
        }
        if (geoObject[value] && geoObject[value].entries.length) {
          if (geoObject[value].entries.length > 5) {
            this.canShowMore = true;
            this.isAllVisible = false;
            this.items = geoObject[value].entries.slice(0, 5);
          } else {
            this.isAllVisible = true;
            this.items = geoObject[value].entries;
          }
        }
        break;
      }
    }
  }

  private updateChipsCounters(audienceFilters: any): void {
    this.chips.forEach((chip) => {
      chip.counter = 0;
    });

    const filterMapping: { [key: string]: AudienceShareEnum } = {
      gender: this.audienceEnum.Gender,
      ageGroups5: this.audienceEnum.Age,
      revenues: this.audienceEnum.Earnings,
      familySquares: this.audienceEnum.Family,
      housings: this.audienceEnum.Housing,
    };

    Object.keys(filterMapping).forEach((key) => {
      const audienceFilterValue = audienceFilters[key];
      const chip = { counter: 0, value: filterMapping[key] };
      if (isArray(audienceFilterValue) && audienceFilterValue.length) {
        chip.counter += audienceFilterValue.length;
        this.chips.push(chip);
        this.sortChips();
      }
    });
  }

  private extractColumns(counts: CountModel[]): void {
    const uniqueKeys: Set<string> = new Set();
    counts.forEach((item) => {
      Object.keys(item.dimensions).forEach((key) => {
        uniqueKeys.add(key);
      });
    });

    this.columns = Array.from(uniqueKeys);
  }

  private sortChips(): void {
    const chipOrder = [
      this.audienceEnum.Gender,
      this.audienceEnum.Age,
      this.audienceEnum.Family,
      this.audienceEnum.Earnings,
      this.audienceEnum.Housing,
    ];

    this.chips.sort(
      (a, b) => chipOrder.indexOf(a.value) - chipOrder.indexOf(b.value),
    );
  }
}
