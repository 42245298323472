import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { PUBLIC_URL } from '@dmc-ng/data-access/injection-token';
import { Observable } from 'rxjs';

import { PublicItem, PublicModel } from './models/public.model';

@Injectable()
export class PublicService {
  private readonly publicUrl = inject(PUBLIC_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getVersionCounting(token: string): Observable<PublicModel<PublicItem[]>> {
    let params = new HttpParams();
    params = params.append('token', token);
    return this.httpClient.get<PublicModel<PublicItem[]>>(
      `${this.publicUrl}/public/shares/`,
      { params },
    );
  }
}
