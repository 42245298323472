import { CountingVersionModel } from '@dmc-ng/data-access/countings';

export interface ShareModel {
  readonly counting: CountingShareModel;
  readonly countingVersion: CountingVersionPublicModel;
}

// NOT AVAILABLE YET, MAYBE LATER ?
export interface CountingShareModel {
  readonly name: string;
  readonly organization: {
    readonly name: string;
    readonly pictureUrl?: string;
  };
  readonly account: {
    readonly name: string;
    readonly pictureUrl?: string;
  };
}

export interface CountingVersionShareModel
  extends Omit<CountingVersionModel, 'filters'> {
  readonly filters: CountFilterShareModel;
}

export interface CountingVersionPublicModel {
  readonly account: AccountPublicModel;
  readonly counting: CountingPublicModel;
  readonly countingVersion: CountingVersionShareModel;
  readonly organization: OrganizationPublicModel;
  readonly resources: ResourcePublicModel;
  readonly user: UserPublicModel;
}

export interface CountFilterShareModel {
  readonly datasetId?: string;
  readonly geo: GeographicalShareModel;
  readonly audience: {
    readonly exclude: boolean;
    readonly gender?: GenderEnum;
    readonly ageGroups5?: string[];
    readonly revenues?: string[];
    readonly familySquares?: string[];
    readonly housings?: string[];
    readonly name?: string;
  }[];
}

export enum GenderEnum {
  Male = 'M',
  Female = 'F',
}

export interface ResourcePublicModel {
  readonly departments?: ResourceGeocoding[];
  readonly districts?: ResourceGeocoding[];
  readonly cities?: ResourceGeocoding[];
  readonly zipCode?: ResourceGeocoding[];
  readonly genders?: ResourceItem[];
  readonly ageGroups5?: ResourceItem[];
  readonly familySquares?: ResourceItem[];
  readonly earnings?: ResourceItem[];
  readonly housings?: ResourceItem[];
  readonly iabCategories?: ResourceItem[];
}

export interface ResourceGeocoding {
  readonly id: string;
  readonly label: string;
  readonly placeId?: string;
}

export interface ResourceItem {
  readonly label: string;
  readonly id: string;
}

export interface AccountPublicModel {
  readonly name: string;
  readonly logoUrl: string;
}

export interface CountingPublicModel {
  readonly name: string;
}

export interface OrganizationPublicModel {
  readonly name: string;
  readonly logoUrl: string;
}

export interface UserPublicModel {
  readonly displayName: string;
  readonly pictureUrl: string;
}

export interface GeographicalShareModel {
  readonly zipCodes?: string[];
  readonly irisCodes?: string[];
  readonly departments?: string[];
  readonly districts?: string[];
  readonly coordinates?: {
    readonly lat: number;
    readonly lng: number;
    readonly radius: number;
  }[];
  readonly cities?: {
    readonly radius: number;
    readonly entries: {
      readonly code: string;
      readonly name: string;
      readonly lat: number;
      readonly lng: number;
    }[];
  };
}

export enum AudienceShareEnum {
  Gender = 'Gender',
  Age = 'Age Range',
  Family = 'Family Square',
  Earnings = 'Earnings',
  Housing = 'Housing',
}

export enum DefineAudienceShareEnum {
  Include = 'include',
  Exclude = 'exclude',
}
